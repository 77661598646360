import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as ExportListSvg } from "assets/exportList.svg";
import { ReactComponent as EmptyIcon } from "assets/icon_location_legend.svg";
import { ReactComponent as NarrowSvg } from "assets/narrow.svg";
import { ReactComponent as SpreadSvg } from "assets/spread.svg";
import Icon from "components/icon/icon";
import Input from "components/input/input";
import FilterColumns from "containers/location-set/filterColumns/filter-columns";
import FilterLocaltios from "containers/location-set/filterLocations/filter-localtions";
import { LeftPanel, RightPanel, zoomLevelMedium } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { MapContext } from "helpers/map";
import { LocationData } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
// import * as XLSX from 'xlsx-style';
import { ReactComponent as IconFilterSvg } from "../../assets/icon_filter.svg";
import { ReactComponent as IconTableSvg } from "../../assets/icon_table.svg";
import { downloadAnalysis } from "./location-set-util";
import styles from "./location-set.module.scss";

interface direction {
	code: "asc" | "desc";
	name: "asc" | "desc";
	zipCode: "asc" | "desc";
	streetNumber: "asc" | "desc";
	place: "asc" | "desc";
	county: "asc" | "desc";
	province: "asc" | "desc";
	country: "asc" | "desc";
}

function LocationSet() {
	const [context, dispatch] = useContext(AppContext);
	const [selected, setSelected] = useState<string>("");
	const [rowCount, setRowCount] = useState(0);
	const [multipleLocationsSearch, setMultipleLocationsSearch] = useState("");
	const [t] = useTranslation();
	const [data, setData] = useState<any[]>([]);
	const [active, setActive] = useState<keyof direction>("code");
	const [direction, setDirection] = useState<direction>({ code: "asc", name: "asc", zipCode: "asc", streetNumber: "asc", place: "asc", county: "asc", province: "asc", country: "asc" });
	const { map } = useContext(MapContext);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [filterLocaltionsEl, setFilterLocaltionsEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const filterLocaltionsOpen = Boolean(filterLocaltionsEl);
	const [invisible, setInvisible] = useState(true);
	const [filterLocaltionsInvisible, setFilterLocaltionsInvisible] = useState(true);
	const [isActiveType, setIsActiveType] = useState<string>("");

	let gList = [
		{ title: "code", show: true, width: 100 },
		{ title: "name", show: true },
	];
	let aList = [
		{ title: "zipCode", show: false },
		{ title: "streetNumber", show: false },
		{ title: "place", show: false },
		{ title: "county", show: false },
		{ title: "province", show: false },
		{ title: "country", show: false },
	];
	const [generalList, setGeneralList] = useState([
		{ title: "code", show: true, width: 100 },
		{ title: "name", show: true },
	]);
	const [addressList, setAddressList] = useState([
		{ title: "zipCode", show: false },
		{ title: "streetNumber", show: false },
		{ title: "place", show: false },
		{ title: "county", show: false },
		{ title: "province", show: false },
		{ title: "country", show: false },
	]);

	const [filterDataInfo, setFilterDataInfo] = useState<{
		county: any[];
		province: any[];
		country: any[];
	}>({
		county: [],
		province: [],
		country: [],
	});

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleApply = (gData: any, aData: any) => {
		setGeneralList(gData);
		setAddressList(aData);
		if (JSON.stringify(gData) === JSON.stringify(gList) && JSON.stringify(aData) === JSON.stringify(aList)) {
			setInvisible(true);
		} else {
			setInvisible(false);
		}
		handleClose();
	};

	const handleFilterLocaltionsClick = (event: React.MouseEvent<HTMLButtonElement> | any, isActiveType = "") => {
		setFilterLocaltionsEl(event.currentTarget);
		setIsActiveType(isActiveType);
	};

	const handleFilterLocaltionsClose = () => {
		setFilterLocaltionsEl(null);
	};

	const handleFilterLocaltionsApply = (filterData: any) => {
		setFilterDataInfo(filterData);
		if (filterData.country.length === 0 && filterData.county.length === 0 && filterData.province.length === 0) {
			setFilterLocaltionsInvisible(true);
		} else {
			setFilterLocaltionsInvisible(false);
		}

		handleFilterLocaltionsClose();
	};

	const handleSort = (property: keyof direction) => {
		if (!data) {
			return;
		}

		if (!property) {
			return;
		}

		setActive(property);

		direction[property] = direction[property] === "asc" ? "desc" : "asc";
		setDirection(direction);

		let arr = [...context.currentMultipleLocations?.items!];
		arr.sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}

			const aValue = a?.[property];
			const bValue = b?.[property];

			if (aValue === undefined) {
				return direction[property] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[property] === "asc" ? 1 : -1;
			}

			if (isNaN(aValue) || isNaN(bValue)) {
				if (aValue < bValue) {
					return direction[active] === "asc" ? 1 : -1;
				}
				if (aValue > bValue) {
					return direction[active] === "asc" ? -1 : 1;
				}
				return 0;
			}

			const aNum = parseFloat(aValue);
			const bNum = parseFloat(bValue);

			if (aNum < bNum) {
				return direction[active] === "asc" ? 1 : -1;
			}
			if (aNum > bNum) {
				return direction[active] === "asc" ? -1 : 1;
			}

			return 0;
		});

		arr = arr.filter((location: LocationData) => {
			return (
				(filterDataInfo.country.length === 0 || filterDataInfo.country.includes(location.country)) &&
				(filterDataInfo.county.length === 0 || filterDataInfo.county.includes(location.county)) &&
				(filterDataInfo.province.length === 0 || filterDataInfo.province.includes(location.province))
			);
		});
		setData([...arr]);
	};

	const handleCreatDefaultData = () => {
		let arr = [...context.currentMultipleLocations!.items!];
		arr.sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}

			const aValue = a?.[active];
			const bValue = b?.[active];

			if (aValue === undefined) {
				return direction[active] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[active] === "asc" ? 1 : -1;
			}

			if (isNaN(aValue) || isNaN(bValue)) {
				if (aValue < bValue) {
					return direction[active] === "asc" ? 1 : -1;
				}
				if (aValue > bValue) {
					return direction[active] === "asc" ? -1 : 1;
				}
				return 0;
			}

			const aNum = parseFloat(aValue);
			const bNum = parseFloat(bValue);

			if (aNum < bNum) {
				return direction[active] === "asc" ? 1 : -1;
			}
			if (aNum > bNum) {
				return direction[active] === "asc" ? -1 : 1;
			}

			return 0;
		});

		arr = arr.filter((location: LocationData) => {
			return (
				(filterDataInfo.country.length === 0 || filterDataInfo.country.includes(location.country)) &&
				(filterDataInfo.county.length === 0 || filterDataInfo.county.includes(location.county)) &&
				(filterDataInfo.province.length === 0 || filterDataInfo.province.includes(location.province))
			);
		});
		setData([...arr]);
		setRowCount(arr.filter((location: LocationData) => location.checked).length);
	};

	useEffect(() => {
		if (context.currentMultipleLocations && context.currentMultipleLocations.items && context.currentMultipleLocations.items.length > 0) {
			handleCreatDefaultData();
		} else {
			setData([]);
			setRowCount(0);
		}
		// dispatch({ type: AppAction.setMultipleLocationsSetLoadings, payload: false })
	}, [context.currentMultipleLocations && context.currentMultipleLocations.items, filterDataInfo]);

	useEffect(() => {
		if (context.rightPanel === RightPanel.Multiple) {
			setSelected("");
		}
	}, [context.rightPanel]);

	const closeEdit = (): void => {
		dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
	};

	const focusLocation = (location: LocationData): void => {
		if (context.currentMultipleLocationInfo?.id === location.id) {
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
			dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: undefined });
		} else {
			dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: location });
			dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
			dispatch({ type: AppAction.setSingleLocation, payload: undefined });
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
			map.setZoomAndCenter(zoomLevelMedium, [location.longitudeWgs, location.latitude]);
		}
	};

	const toggleLocation = (evt: any, location: any): void => {
		evt.stopPropagation();

		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((item: LocationData) => {
			if (item.id === location.id) {
				item.checked = !item.checked;
			}
			return item;
		});

		let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
			if (locationSet.id === context.currentMultipleLocations!.id) {
				locationSet.items = context.currentMultipleLocations!.items;
			}
			return locationSet;
		});
		let num: number = data?.filter((location: LocationData) => location.checked).length || 0;

		setRowCount(num);
		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});

		dispatch({
			type: AppAction.setMultipleLocations,
			payload: multipleLocations,
		});
	};

	const patchLocation = (item: any): void => {
		dispatch({
			type: AppAction.setEditMultipleLocation,
			payload: item,
		});

		dispatch({
			type: AppAction.setLeftPanel,
			payload: LeftPanel.EditLocation,
		});
	};

	const handleDeleteLocationSet = (location: any) => {
		dispatch({
			type: AppAction.setDeleteLocationModal,
			payload: location,
		});
	};

	const handleChangeLocationsSize = () => {
		dispatch({ type: AppAction.setLocationsSize, payload: !context.locationsSize });
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		let locations: any = context.currentMultipleLocations!.items?.map((location: any) => {
			data.forEach((item: any) => {
				if (item.id === location.id) {
					location.checked = event.target.checked;
				}
			});
			return location;
		});

		let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
			if (locationSet.id === context.currentMultipleLocations!.id) {
				locationSet.items = context.currentMultipleLocations!.items;
			}
			return locationSet;
		});

		dispatch({
			type: AppAction.setMultipleLocations,
			payload: multipleLocations,
		});

		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: { ...context.currentMultipleLocations, items: locations },
		});

		if (event.target.checked) {
			setRowCount(data.length);
		} else {
			setRowCount(0);
		}
	};

	const downloadLocationList = () => {
		downloadAnalysis(context, t);
	};

	const exportLocationList = () => {
		if (!context.currentMultipleLocations) {
			return;
		}
		if (!context.currentMultipleLocations.items) {
			return;
		}

		const name = context.currentMultipleLocations.name;

		// const data1 = [[t("headerName"), name]];
		const data1 = [{ "": t("headerName"), " ": name }];
		const worksheet1 = XLSX.utils.json_to_sheet(data1);

		// if (worksheet1["!ref"]) {
		// 	const range = XLSX.utils.decode_range(worksheet1["!ref"]);
		// 	for (let col = range.s.c; col <= range.e.c; col++) {
		// 		worksheet1[XLSX.utils.encode_cell({ c: col, r: 0 })] = null;
		// 	}
		// }

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet1, t("sheet.header"));

		const data: any[] = context.currentMultipleLocations.items.map((e) => {
			const { code, name, country, countryISO3, province, place, county, street, number, zipCode, coordinateSystem, latitude, longitude } = e;

			return {
				[t("importLocationSet.id")]: code,
				[t("importLocationSet.name")]: name,
				[t("importLocationSet.country")]: country,
				[t("importLocationSet.countryISO3")]: countryISO3,
				[t("importLocationSet.province")]: province,
				[t("importLocationSet.place")]: place,
				[t("importLocationSet.county")]: county,
				[t("importLocationSet.street")]: street,
				[t("importLocationSet.number")]: !number || number === "undefined" ? "" : number,
				[t("importLocationSet.zipCode")]: !zipCode || zipCode === "undefined" ? "" : zipCode,
				[t("importLocationSet.coordinateSource")]: coordinateSystem,
				[t("importLocationSet.latitude")]: latitude,
				[t("importLocationSet.longitude")]: longitude,
			};
		});
		console.log(data, "data");
		const worksheet = XLSX.utils.json_to_sheet(data);

		XLSX.utils.book_append_sheet(workbook, worksheet, t("sheet.site"));

		const fileName = name.replace(/\s+/g, "_");

		XLSX.writeFile(workbook, `${fileName}.xlsx`);
	};

	return (
		<div
			className={styles.set}
			style={{ width: context.locationsSize ? "800px" : "400px" }}
		>
			<div className={styles.header}>
				<div className={styles.title}>
					<Icon
						icon="arrow_back"
						onClick={closeEdit}
					/>
					<div className="flex items-center">
						<span> {context.currentMultipleLocations && context.currentMultipleLocations.name}</span>
						<Icon
							icon="create"
							onClick={() => setLeftPanel(dispatch, LeftPanel.EditLocationSet)}
						/>
					</div>
					<div className={styles.close}>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
				{context.multipleLocations && (
					<div className="flex items-center justify-between mx-[10px] mb-[16px]">
						<div className={styles.search}>
							<Input
								icon="search"
								placeholder={t("locationSet.searchPlaceholder")}
								onChange={(e) => {
									setMultipleLocationsSearch(e.target.value);
								}}
								value={multipleLocationsSearch}
							/>
						</div>
						<div className={styles.switch}>
							{/* {context.locationsSize ? <NarrowSvg /> : <SpreadSvg />} */}
							{context.locationsSize ? (
								<div
									className="tooltip mr-[5px]"
									data-tip={t("tooltip.collapse")}
								>
									<IconButton
										onClick={handleChangeLocationsSize}
										color="success"
										sx={{
											width: "36px",
											height: "36px",
											"&:hover": {
												background: "rgba(0, 0, 0, 0.04)",
											},
										}}
									>
										<NarrowSvg style={{ width: "20px", height: "17px" }} />
									</IconButton>
								</div>
							) : (
								<div
									className="tooltip  mr-[5px]"
									data-tip={t("tooltip.expand")}
								>
									<IconButton
										onClick={handleChangeLocationsSize}
										color="success"
										sx={{
											width: "36px",
											height: "36px",
											"&:hover": {
												background: "rgba(0, 0, 0, 0.04)",
											},
										}}
									>
										<SpreadSvg style={{ width: "20px", height: "17px" }} />
									</IconButton>
								</div>
							)}
							<div
								className="tooltip mr-[5px]"
								data-tip={t("locationSet.columnsTitle")}
							>
								<Badge
									overlap="circular"
									variant="dot"
									invisible={invisible}
									sx={{
										".MuiBadge-badge": { background: "#ffa02f" },
									}}
								>
									<IconButton
										onClick={handleClick}
										color="success"
										sx={{
											width: "36px",
											height: "36px",
											"&:hover": {
												background: "rgba(0, 0, 0, 0.04)",
											},
										}}
										style={{
											background: open || !invisible ? "#c1e5ca" : "",
										}}
									>
										<IconTableSvg
											style={{
												fill: open || !invisible ? "green" : "",
											}}
											className="focus:outline-none w-[20px] h-[18px]"
										/>
									</IconButton>
								</Badge>
							</div>

							<div
								className="tooltip"
								data-tip={t("locationSet.filterLocations")}
							>
								<Badge
									overlap="circular"
									variant="dot"
									invisible={filterLocaltionsInvisible}
									sx={{
										".MuiBadge-badge": { background: "#ffa02f" },
									}}
								>
									<IconButton
										onClick={handleFilterLocaltionsClick}
										color="success"
										sx={{
											width: "36px",
											height: "36px",
											"&:hover": {
												background: "rgba(0, 0, 0, 0.04)",
											},
										}}
										style={{
											background: filterLocaltionsOpen || !filterLocaltionsInvisible ? "#c1e5ca" : "",
										}}
									>
										<IconFilterSvg
											style={{
												fill: filterLocaltionsOpen || !filterLocaltionsInvisible ? "green" : "",
											}}
											className="focus:outline-none w-[20px] h-[12px]"
										/>
									</IconButton>
								</Badge>
							</div>
						</div>
					</div>
				)}
			</div>
			{context.multipleLocationsSetLoadings ? (
				<div className={styles.loading}>
					<div className="loading loading-spinner loading-lg"></div>
					<div>{t("layers.loadingLocations")}</div>
				</div>
			) : (
				<>
					{context.currentMultipleLocations && context.currentMultipleLocations.items?.length ? (
						<Paper
							sx={{ width: "100%", overflow: "hidden", height: "556px", position: "relative", boxShadow: "none", flex: 1, display: "flex", flexDirection: "column" }}
							className={styles.table}
						>
							{(filterDataInfo.province.length > 0 || filterDataInfo.country.length > 0 || filterDataInfo.county.length > 0) && (
								<div className={styles.tableFilterInfo}>
									{filterDataInfo.province.length > 0 && (
										<Chip
											label={t("locations.add.province") + ": " + filterDataInfo.province.join(", ")}
											variant="outlined"
											icon={
												<Tooltip title={t("locationSet.uploadFilter")}>
													<div
														onClick={(event) => handleFilterLocaltionsClick(event, "province")}
														className="material-icons"
														style={{ cursor: "pointer", background: "#007934", color: "#fff", borderRadius: "50%", fontSize: "18px" }}
													>
														more_horiz
													</div>
												</Tooltip>
											}
											onDelete={() => {
												handleFilterLocaltionsApply({ ...filterDataInfo, province: [] });
											}}
										/>
									)}
									{filterDataInfo.country.length > 0 && (
										<Chip
											label={t("locations.add.country") + ": " + filterDataInfo.country.join(", ")}
											variant="outlined"
											icon={
												<Tooltip title={t("locationSet.uploadFilter")}>
													<div
														onClick={(event) => handleFilterLocaltionsClick(event, "country")}
														className="material-icons"
														style={{ cursor: "pointer", background: "#007934", color: "#fff", borderRadius: "50%", fontSize: "18px" }}
													>
														more_horiz
													</div>
												</Tooltip>
											}
											onDelete={() => {
												handleFilterLocaltionsApply({ ...filterDataInfo, country: [] });
											}}
										/>
									)}
									{filterDataInfo.county.length > 0 && (
										<Chip
											label={t("locations.add.county") + ": " + filterDataInfo.county.join(", ")}
											variant="outlined"
											icon={
												<Tooltip title={t("locationSet.uploadFilter")}>
													<div
														onClick={(event) => handleFilterLocaltionsClick(event, "county")}
														className="material-icons"
														style={{ cursor: "pointer", background: "#007934", color: "#fff", borderRadius: "50%", fontSize: "18px" }}
													>
														more_horiz
													</div>
												</Tooltip>
											}
											onDelete={() => {
												handleFilterLocaltionsApply({ ...filterDataInfo, county: [] });
											}}
										/>
									)}
								</div>
							)}
							<TableContainer sx={{ height: "100%", paddingBottom: "56px", flex: 1 }}>
								<Table
									stickyHeader
									aria-label="sticky  table"
									size="small"
								>
									<TableHead>
										<TableRow className={styles.row}>
											<TableCell
												style={{ padding: 0, width: 50 }}
												align="center"
											>
												<Checkbox
													color="success"
													indeterminate={rowCount > 0 && data!.length !== rowCount}
													checked={rowCount > 0 && data!.length === rowCount}
													onChange={handleSelectAllClick}
													inputProps={{
														"aria-label": "select all desserts",
													}}
												/>
											</TableCell>
											{[...generalList, ...addressList].map(
												(item: any, index: number) =>
													item.show && (
														<TableCell
															align="left"
															style={item.width ? { width: item.width } : { minWidth: 150 }}
															key={index}
														>
															<TableSortLabel
																active={active === item.title}
																onClick={() => handleSort(item.title)}
																direction={(direction as any)[item.title]}
															>
																{t("locations.add." + item.title)}
															</TableSortLabel>
														</TableCell>
													)
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.map(
											(location: LocationData, index: number) =>
												(multipleLocationsSearch === "" || location.name.toLowerCase().includes(multipleLocationsSearch.toLowerCase())) && (
													<TableRow
														key={location.id}
														selected={location.id === (context.currentMultipleLocationInfo ? context.currentMultipleLocationInfo.id : "")}
														className={styles.row}
														onClick={() => focusLocation(location)}
														sx={{
															"&.Mui-selected,&.Mui-selected&:hover": {
																backgroundColor: "rgba(14, 138, 63, 0.08)!important",
															},
															"&.Mui-selected .delete-set": {
																background: "linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px)!important",
															},
														}}
													>
														<TableCell
															style={{ padding: 0 }}
															align="center"
														>
															<Checkbox
																color="success"
																checked={location.checked}
																onClick={(evt: any) => toggleLocation(evt, location)}
															/>
														</TableCell>
														{[...generalList, ...addressList].map(
															(item: any, index: number) =>
																item.show && (
																	<TableCell
																		align="left"
																		key={index}
																	>
																		{(location as any)[item.title]}
																	</TableCell>
																)
														)}
														<TableCell style={{ padding: 0 }}>
															<div className={styles.delete}>
																<div
																	className={`${styles.icons} delete-set`}
																	onClick={(evt) => evt.stopPropagation()}
																>
																	<div
																		className="tooltip tooltip-bottom"
																		data-tip={t("tooltip.edit")}
																	>
																		<Icon
																			icon="create"
																			onClick={() => patchLocation(location)}
																		/>
																	</div>
																	<div
																		className="tooltip tooltip-bottom"
																		data-tip={t("tooltip.delete")}
																	>
																		<Icon
																			icon="delete"
																			// onClick={() => deleteLocation(location, index)}
																			onClick={() => handleDeleteLocationSet(location)}
																		/>
																	</div>
																</div>
															</div>
														</TableCell>
													</TableRow>
												)
										)}
									</TableBody>
								</Table>
								<div
									className={styles.pagination}
									style={{ bottom: context.locationsSize ? "0" : "" }}
								>
									{t("locations.table.pagination")}
									{rowCount} {t("locations.table.of")} {data!.length}
								</div>
							</TableContainer>
						</Paper>
					) : (
						<div className={styles.empty}>
							<EmptyIcon></EmptyIcon>
							<div className="my-[15px]">{t("locations.empty")}</div>
							<div>{t("locations.addEmpty")}</div>
						</div>
					)}
				</>
			)}

			{open && (
				<FilterColumns
					anchorEl={anchorEl}
					generalList={generalList}
					addressList={addressList}
					handleClose={handleClose}
					handleApply={handleApply}
				/>
			)}

			{filterLocaltionsOpen && (
				<FilterLocaltios
					anchorEl={filterLocaltionsEl}
					data={context.currentMultipleLocations!.items}
					defaultData={filterDataInfo}
					isActiveType={isActiveType}
					handleClose={handleFilterLocaltionsClose}
					handleApply={handleFilterLocaltionsApply}
				/>
			)}

			<div className={styles.footer}>
				<Button
					variant="outlined"
					color="success"
					style={{ width: "100%" }}
					endIcon={<ExportListSvg style={{ width: "18px", height: "18px", fill: "green" }} />}
					disabled={!rowCount}
					onClick={downloadLocationList}
				>
					{t("exportLocationList")}
				</Button>
			</div>
		</div>
	);
}

export default LocationSet;
