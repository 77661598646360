import { AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./options.module.scss";
import { RightPanel } from "helpers/constants";

export enum Option {
	HAZARD = "hazard",
	CLIMATE = "climate",
}

interface Props {
	selected: Option;
	onChange: (option: Option) => void;
	hazardLayerLength?: number;
	climateLayerLength?: number;
}

function Options(props: Props) {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const [hasHazard, setHasHazard] = useState(false);
	const [hasClimate, setHasClimate] = useState(false);

	useEffect(() => {
		if (!context.menu || !context.menu.length) {
			return;
		}

		// if (context.analyseModal === undefined) {
		// 	return;
		// }

		if (context.analyseModal === "1" || (context.rightPanel === RightPanel.Single && context.singleLocationAnalysed)) {
			if (context.menu.find((item) => item.id === "102001")) {
				setHasHazard(true);
			}

			if (context.menu.find((item) => item.id === "102002")) {
				setHasClimate(true);
			}
			return;
		}

		if (context.analyseModal === "2" || (context.rightPanel === RightPanel.Multiple && context.multipleLocationsAnalysed)) {
			if (context.menu.find((item) => item.id === "103002")) {
				setHasHazard(true);
			}

			if (context.menu.find((item) => item.id === "103003")) {
				setHasClimate(true);
			}
			return;
		}
	}, []);

	return (
		<div className={styles.options}>
			{hasHazard && (
				<div
					className={props.selected === Option.HAZARD ? styles.buttonActive : styles.button}
					onClick={() => props.onChange(Option.HAZARD)}
				>
					{t("options.hazard")}
					{props.hazardLayerLength ? `(${props.hazardLayerLength})` : ""}
				</div>
			)}
			{hasHazard && hasClimate && <div className={styles.divider}></div>}
			{hasClimate && (
				<div
					className={props.selected === Option.CLIMATE ? styles.buttonActive : styles.button}
					onClick={() => props.onChange(Option.CLIMATE)}
				>
					{t("options.climate")}
					{props.climateLayerLength ? `(${props.climateLayerLength})` : ""}
				</div>
			)}
		</div>
	);
}

export default Options;
