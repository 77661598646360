import { useState, useEffect, useContext } from "react";
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableSortLabel,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Tabs,
	Tab,
	Toolbar,
	IconButton,
	TextField,
	Box,
	InputAdornment,
	Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./login-record.module.scss";
import { ReactComponent as ImportIcon } from "assets/location_set_import.svg";
import { useTranslation } from "react-i18next";
import { getLoginLogData, importRecords, downloadLoginList, deleteRecords, batchUpdateWithNotMatch } from "api/layer";
import { AppContext, AppAction } from "helpers/context";
import StartTimeDropdown from "./start-time-dropdown/start-time-dropdown";
import EndTimeDropdown from "./end-time-dropdown/end-time-dropdown";
import Modal from "components/modal/modal";
import dayjs from "dayjs";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

function LoginRecord() {
	const { t } = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [logData, setLogData] = useState<any[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [rowCount, setRowCount] = useState<any[]>([]);
	const [deleteModalData, setDeleteModalData] = useState(false);
	const [currentData, setCurrentData] = useState<any>(undefined);
	const [sortByField, setSortByField] = useState<"batch_id" | "user_uuid" | "who" | "instance_id" | "ip" | "country" | "province" | "log_type" | "login_time">("login_time");
	const [sortOrder, setSortOrder] = useState<{
		batch_id: "asc" | "desc",
		user_uuid: "asc" | "desc",
		who: "asc" | "desc",
		instance_id: "asc" | "desc",
		ip: "asc" | "desc",
		country: "asc" | "desc",
		province: "asc" | "desc",
		log_type: "asc" | "desc",
		login_time: "asc" | "desc"
	}>({
		batch_id: "asc",
		user_uuid: "asc",
		who: "asc",
		instance_id: "asc",
		ip: "asc",
		country: "asc",
		province: "asc",
		log_type: "asc",
		login_time: "desc",
	});
	const [searchIpt, setSearchIpt] = useState("");
	const [searchData, setSearchData] = useState<{
		keyword: string;
		startLoginTime: any;
		endLoginTime: any;
	}>({
		keyword: "",
		startLoginTime: null,
		endLoginTime: null,
	});

	const handleBatchIp = () => {
		batchUpdateWithNotMatch().then((res: any) => {
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "batchIpLocation",
						resMessage: res.message,
					},
				});
			}
		})
	}

	const hanldeDeleteAll = () => {
		setDeleteModalData(false);
		const params = currentData ? [currentData.id] : rowCount;
		deleteRecords(params).then((res: any) => {
			setCurrentData(undefined);
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteRecord",
						message: "deleteRecordSuccess",
					},
				});
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteRecord",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return;
			}
			handleChangePage(undefined, 0);
		});
	};

	const handleSelectAllClick = (event: any) => {
		const checked = event.target.checked;
		setRowCount(checked ? logData.map((row: any) => row.id) : []);
	};

	const hanldeDownload = () => {
		downloadLoginList({ ...searchData, language: context.language }).then((res: any) => {
			console.log(res);
			const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${t("userSystem.loginRecordDownload")}.xls`);
			document.body.appendChild(link);
			link.click();
			link.onload = () => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			};
		});
	};

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	const handleSearch = (params: any) => {
		setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
		if ((params.name === "startLoginTime" || params.name === "endLoginTime") && params.value && params.value.format("YYYY-MM-DD") === "Invalid Date") {
			return;
		}
		setPage(0);
		handleGetLoginLogData({ pageNo: 1, search: { ...searchData, [params.name]: params.value } });
	};

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			handleSearch({ name: "keyword", value: event.target.value });
		}
	};

	const handleImportRecords = (event: any) => {
		const file = event.target.files[0];
		if (!file) return;
		const formData = new FormData();
		formData.append("file", file);
		importRecords(formData).then((res: any) => {
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "importLoginRecord",
						message: "importLoginRecordSuccess",
					},
				});
				handleGetLoginLogData();
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "importLoginRecord",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return;
			}
		});
	};

	const handleChangePage = (event: any, newPage: number) => {
		setRowCount([]);
		setPage(newPage);
		handleGetLoginLogData({ pageNo: newPage + 1 });
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowCount([]);
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		handleGetLoginLogData({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
	};

	const handleGetLoginLogData = async ({
		pageNo = page + 1,
		pageSize = rowsPerPage,
		sortOrderAsc = sortOrder[sortByField] === "asc",
		sortByFields = sortByField, search = searchData
	} = {}) => {
		setIsLoading(true);
		let params = { ...search };
		if (search.startLoginTime) {
			params.startLoginTime = search.startLoginTime.format("YYYY-MM-DD");
		}
		if (search.endLoginTime) {
			params.endLoginTime = search.endLoginTime.format("YYYY-MM-DD");
		}
		const res: any = await getLoginLogData({ pageNo, pageSize, sortOrderAsc, sortByField: sortByFields, ...params });
		setIsLoading(false);
		if (res.code !== 200) return;
		setLogData(res.data.rows);
		setTotalCount(res.data.totalCount * 1 || 0);
	};

	useEffect(() => {
		handleGetLoginLogData();
	}, []);

	return (
		<div className={styles.container}>
			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<div>
					{context.menu && context.menu.find((item) => item.id === "100002") && (
						<Button
							variant="contained"
							color="success"
							size="small"
							component="label"
							endIcon={<ImportIcon style={{ width: "14px", height: "14px", fill: "white" }} />}
							style={{ marginRight: "10px" }}
						>
							{t("userSystem.importRecords")}
							<VisuallyHiddenInput
								type="file"
								onChange={handleImportRecords}
							/>
						</Button>
					)}
					<Button
						size="small"
						variant="outlined"
						color="success"
						style={{ padding: "4px 10px", marginRight: "10px" }}
						onClick={hanldeDownload}
					>
						{t("single.buttons.run")}
					</Button>
					{context.menu && context.menu.find((item) => item.id === "100002") && (
						<Button
							size="small"
							variant="text"
							color="error"
							style={{ padding: "4px 10px", marginRight: "10px" }}
							disabled={rowCount.length === 0}
							onClick={() => setDeleteModalData(true)}
						>
							{t("userSystem.deleteTenant.delete")}
						</Button>
					)}
					<Button
						size="small"
						variant="outlined"
						color="success"
						style={{ padding: "4px 10px" }}
						onClick={handleBatchIp}
					>
						{t("userSystem.uploadIpLocation")}
					</Button>
				</div>

				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{ color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)" }}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>

			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<TextField
							color="success"
							variant="outlined"
							size="small"
							placeholder={t("userSystem.filterByKeyword")}
							value={searchIpt}
							onChange={(e) => setSearchIpt(e.target.value)}
							onKeyDown={handleKeyDown}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<span className="material-icons">filter_list</span>
									</InputAdornment>
								),
								endAdornment: searchIpt && (
									<InputAdornment position="end">
										<span
											className="material-icons cursor-pointer"
											onClick={() => {
												setSearchIpt("");
												searchData.keyword && handleSearch({ name: "keyCode", value: "" });
											}}
										>
											close
										</span>
									</InputAdornment>
								),
							}}
							sx={{
								width: "250px",
								"& .MuiOutlinedInput-notchedOutline": {
									borderWidth: "0",
								},
								"& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
									fontSize: "14px",
								},
							}}
						/>
						<StartTimeDropdown
							defaultTime={searchData.startLoginTime}
							onSave={(value) => handleSearch({ name: "startLoginTime", value })}
						/>
						<EndTimeDropdown
							defaultTime={searchData.endLoginTime}
							onSave={(value) => handleSearch({ name: "endLoginTime", value })}
						/>
					</Box>
					<IconButton
						onClick={() => setShowFilters(false)}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)}

			<div style={{ flex: 1, overflow: "auto" }}>
				<Table
					stickyHeader
					aria-label="sticky  table"
					size="small"
				>
					<TableHead>
						<TableRow>
							<TableCell
								style={{ padding: 0, width: 50 }}
								align="center"
							>
								<Checkbox
									color="success"
									indeterminate={rowCount.length > 0 && rowsPerPage !== rowCount.length}
									checked={rowCount.length > 0 && rowsPerPage === rowCount.length}
									onChange={handleSelectAllClick}
									inputProps={{
										"aria-label": "select all desserts",
									}}
								/>
							</TableCell>
							<TableCell style={{ minWidth: 70 }}>{t("userSystem.index")}</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "batch_id"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.batch_id === "asc"), sortByFields: "batch_id" })
										setSortByField("batch_id")
										let sort: any = {
											...sortOrder,
											batch_id: sortOrder.batch_id === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.batch_id}
								>
									{t("userSystem.batchId")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 120 }}>
								<TableSortLabel
									active={sortByField === "user_uuid"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.user_uuid === "asc"), sortByFields: "user_uuid" })
										setSortByField("user_uuid")
										let sort: any = {
											...sortOrder,
											user_uuid: sortOrder.user_uuid === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.user_uuid}
								>
									{t("userSystem.useruuid")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 120 }}>
								<TableSortLabel
									active={sortByField === "who"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.who === "asc"), sortByFields: "who" })
										setSortByField("who")
										let sort: any = {
											...sortOrder,
											who: sortOrder.who === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.who}
								>
									{t("userSystem.username")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								<TableSortLabel
									active={sortByField === "instance_id"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.instance_id === "asc"), sortByFields: "instance_id" })
										setSortByField("instance_id")
										let sort: any = {
											...sortOrder,
											instance_id: sortOrder.instance_id === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.instance_id}
								>
									{t("userSystem.instanceid")}
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "ip"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.ip === "asc"), sortByFields: "ip" })
										setSortByField("ip")
										let sort: any = {
											...sortOrder,
											ip: sortOrder.ip === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.ip}
								>
									{t("userSystem.ip")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								<TableSortLabel
									active={sortByField === "country"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.country === "asc"), sortByFields: "country" })
										setSortByField("country")
										let sort: any = {
											...sortOrder,
											country: sortOrder.country === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.country}
								>
									{t("userSystem.country")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								<TableSortLabel
									active={sortByField === "province"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.province === "asc"), sortByFields: "province" })
										setSortByField("province")
										let sort: any = {
											...sortOrder,
											province: sortOrder.province === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.province}
								>
									{t("userSystem.region")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 120 }}>
								<TableSortLabel
									active={sortByField === "log_type"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.log_type === "asc"), sortByFields: "log_type" })
										setSortByField("log_type")
										let sort: any = {
											...sortOrder,
											log_type: sortOrder.log_type === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.log_type}
								>
									{t("userSystem.logtype")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 120 }}>
								<TableSortLabel
									active={sortByField === "login_time"}
									onClick={() => {
										handleGetLoginLogData({ sortOrderAsc: !(sortOrder.login_time === "asc"), sortByFields: "login_time" })
										setSortByField("login_time")
										let sort: any = {
											...sortOrder,
											login_time: sortOrder.login_time === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.login_time}
								>
									{t("userSystem.logintime")}
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={10}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : (
							logData.map((log: any, index: number) => (
								<TableRow
									key={log.id}
									className={styles.row}
								>
									<TableCell
										style={{ padding: 0, width: 50 }}
										align="center"
									>
										<Checkbox
											color="success"
											checked={rowCount.includes(log.id)}
											onChange={(event) => {
												if (event.target.checked) {
													setRowCount([...rowCount, log.id]);
												} else {
													setRowCount(rowCount.filter((id) => id !== log.id));
												}
											}}
											inputProps={{
												"aria-label": "select dessert",
											}}
										/>
									</TableCell>
									<TableCell>{index + 1}</TableCell>
									<TableCell>{log.batchId}</TableCell>
									<TableCell style={{ wordBreak: "break-word" }}>{log.userUuid}</TableCell>
									<TableCell>{log.who}</TableCell>
									<TableCell>{log.instanceId}</TableCell>
									<TableCell>{log.ip}</TableCell>
									<TableCell>{log.country}</TableCell>
									<TableCell>{log.province}</TableCell>
									<TableCell>{log.logType}</TableCell>
									<TableCell>{log.loginTime}</TableCell>
									{context.menu && context.menu.find((item) => item.id === "100002") && (
										<TableCell style={{ padding: 0 }}>
											<div className={styles.delete}>
												<div
													className={`${styles.icons} delete-set`}
													onClick={(evt) => evt.stopPropagation()}
												>
													<div
														className="tooltip tooltip-bottom"
														data-tip={t("tooltip.delete")}
													>
														<IconButton
															onClick={() => {
																setCurrentData(log);
																setDeleteModalData(true);
															}}
														>
															<span className="material-icons">delete</span>
														</IconButton>
													</div>
												</div>
											</div>
										</TableCell>
									)}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</div>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t("userSystem.rowsPerPage")}
			/>
			{deleteModalData && (
				<Modal
					header={t("userSystem.deleteLoginRecordTitle")}
					opened={deleteModalData}
					onClose={() => {
						setCurrentData(undefined);
						setDeleteModalData(false);
					}}
				>
					<div className={styles.info}>
						<div className={styles.text}>{`${t("userSystem.deleteLoginRecordMessage")}`}</div>

						<div className="flex justify-end mt-2">
							<Button
								variant="text"
								color="success"
								onClick={() => {
									setCurrentData(undefined);
									setDeleteModalData(false);
								}}
								style={{ outline: "none" }}
							>
								{t("deleteLocation.cancel")}
							</Button>
							&nbsp;&nbsp;
							<Button
								variant="contained"
								color="error"
								onClick={hanldeDeleteAll}
							>
								{t("deleteLocation.delete")}
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default LoginRecord;
