import { useState, useEffect, useContext } from "react";
import { Button, Menu, MenuItem, Checkbox, ListItemText, ListItemIcon, Typography, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppContext, AppAction } from "helpers/context";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en"; // 导入中文语言包
import "dayjs/locale/zh"; // 导入中文语言包

interface Props {
    defaultTime: any;
    onSave: (roles: any) => void;
}

const EndTimeDropdown = (props: Props) => {
    const [t] = useTranslation();
    const [context, dispatch] = useContext(AppContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [endTime, setEndTime] = useState(props.defaultTime);


    const handleClick = (event: any) => {
        setEndTime(props.defaultTime);
        setAnchorEl(event.currentTarget);
    };

    const handleSave = () => {
        props.onSave(endTime);
        handleClose()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div style={{ marginRight: "16px" }}>
            <Button
                color="success"
                variant="text"
                onClick={handleClick}
                endIcon={open ? <span className="material-icons">keyboard_arrow_down</span> : <span className="material-icons">keyboard_arrow_up</span>}
                style={{ color: "#333", textTransform: "none" }}
            >
                {t("userSystem.endDate")}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div className="p-2">
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={context.language === "cn" ? "zh" : "en"}

                    >
                        <DatePicker
                            label={t("userSystem.endDate")}
                            value={endTime}
                            format="YYYY-MM-DD"
                            onChange={(event) => { setEndTime(event) }}
                            sx={{
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "green",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "green",
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <div style={{ marginTop: "8px", display: "flex", justifyContent: "space-between" }}>
                        <Button
                            color="success"
                            disabled={!endTime}
                            onClick={() => setEndTime(null)}
                        >
                            {t("single.buttons.reset")}
                        </Button>
                        <div>
                            <Button
                                color="success"
                                onClick={handleClose}
                            >
                                {t("single.buttons.close")}
                            </Button>
                            <Button
                                onClick={handleSave}
                                color="success"
                                variant="contained"
                            >
                                {t("locations.add.save")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Menu>
        </div>
    );
};

export default EndTimeDropdown;
