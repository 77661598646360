import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, TableSortLabel } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { downloadTenantData, getRoleData, getTenant } from "api/layer";
import { statusBgColor } from "helpers/helpers";
import { getLanguage } from "helpers/translations";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteTenantModal from "./delete-tenant-modal/delete-tenant-modal";
import RoleDropdown from "./role-dropdown/role-dropdown";
import SearchInput from "./search-input/search-input";
import StatusDropdown from "./status-dropdown/status-dropdown";
import TenantAddModal from "./tenant-add-modal/tenant-add-modal";
// import TenantDropdown from "./tenant-dropdown/tenant-dropdown";
import { AppContext } from "helpers/context";
import styles from "./tenant.module.scss";

const Tenant = () => {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [showFilters, setShowFilters] = useState(false);
	const [open, setOpen] = useState(false);
	const [openDeleteTenantModal, setOpenDeleteTenantModal] = useState<boolean>(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tableData, setTableData] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [currentTenant, setCurrentTenant] = useState<any>();
	const [roleData, setRoleData] = useState([]);
	const [roleDropdown, setRoleDropdown] = useState<any[]>([]);
	const [statusDropdown, setStatusDropdown] = useState<any[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [sortByField, setSortByField] = useState<"tenant_code" | "tenant_name" | "role_names" | "user_count" | "status" | "start_time" | "end_time">("start_time");
	const [sortOrder, setSortOrder] = useState<{
		tenant_code: "asc" | "desc",
		tenant_name: "asc" | "desc",
		role_names: "asc" | "desc",
		user_count: "asc" | "desc",
		status: "asc" | "desc",
		start_time: "asc" | "desc",
		end_time: "asc" | "desc"
	}>({
		tenant_code: "asc",
		tenant_name: "asc",
		role_names: "asc",
		user_count: "asc",
		status: "asc",
		start_time: "desc",
		end_time: "asc",
	});
	const hanldeDownload = () => {
		const param: any = {
			keyCode: searchKeyword,
			language: context.language,
		};
		if (statusDropdown && statusDropdown.length > 0) {
			param.statuses = statusDropdown;
		}

		if (roleDropdown && roleDropdown.length > 0) {
			param.roleIds = roleDropdown.map((role: any) => role.id);
		}
		downloadTenantData(param).then((res: any) => {
			console.log(res);
			const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${t("userSystem.tenant")}.xls`);
			document.body.appendChild(link);
			link.click();
			link.onload = () => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			};
		});
	};

	const initData = async () => {
		setIsLoading(true);

		const param: any = {
			tenantType: "",
			keyCode: searchKeyword,
			tenantCode: ""
		};

		const pageParam: any = {
			pageNo: page + 1,
			pageSize: rowsPerPage,
			sortOrderAsc: sortOrder[sortByField] === "asc",
			sortByField: sortByField
		};

		if (statusDropdown && statusDropdown.length > 0) {
			param.statuses = statusDropdown;
		}

		if (roleDropdown && roleDropdown.length > 0) {
			param.roleIds = roleDropdown.map((role: any) => role.id);
		}

		getTenant(param, pageParam).then((res: any) => {
			if (res.code !== 200 || !res.data || !res.data.rows) {
				return;
			}

			setTableData(res.data.rows);
			setTotalCount(res.data.totalCount * 1 || 0);
			setIsLoading(false);
		});
	};

	const handleSearch = (keyword: string) => {
		setSearchKeyword(keyword);
		setPage(0);
	};

	const handleStatusDropdown = (value: any) => {
		setStatusDropdown(value);
		setPage(0);
	};

	const handleRoleDropdown = (value: any) => {
		setRoleDropdown(value);
		setPage(0);
	};

	const handleSave = () => {
		setOpen(false);
		initData();
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));

		setPage(0);
	};

	const toggleFilters = () => {
		setShowFilters((prev) => !prev);
	};

	const clearFilters = () => {
		setShowFilters(false);
	};

	const handleGetRoleData = async () => {
		const res: any = await getRoleData();
		if (res.code !== 200) {
			return;
		}
		setRoleData(res.data);
	};

	useEffect(() => {
		// initData();
		handleGetRoleData();
	}, []);

	useEffect(() => {
		initData();
	}, [page, rowsPerPage, searchKeyword, roleDropdown, statusDropdown, sortByField, sortOrder]);

	const getStatusColor = (status: string) => {
		switch (status) {
			case "1":
				return "green";
			case "2":
				return "red";
			case "3":
				return "orange";
			default:
				return "gray";
		}
	};

	const getStatusText = (status: string) => {
		const language = getLanguage();
		if (language === "en") {
			switch (status) {
				case "1":
					return "Active";
				case "2":
					return "Overdue";
				case "3":
					return "Closed";
				default:
					return "Not Active";
			}
		}

		switch (status) {
			case "1":
				return "正常";
			case "2":
				return "失效";
			case "3":
				return "停用";
			default:
				return "未激活";
		}
	};

	const handleDelete = (tenant: any) => {
		setCurrentTenant(tenant);
		setOpenDeleteTenantModal(true);
	};

	const closeDeleteTenantModal = () => {
		setOpenDeleteTenantModal(false);
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.tenant")}</div>

			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<div>
					{context.menu && context.menu.find((item) => item.id === "100002") && (
						<Button
							variant="contained"
							color="success"
							size="small"
							endIcon={<span className="material-icons">add</span>}
							onClick={() => {
								setOpen(true);
								setCurrentTenant(null);
							}}
						>
							{t("userSystem.addTenant")}
						</Button>
					)}
					<Button
						size="small"
						variant="outlined"
						color="success"
						style={{ padding: "4px 10px", margin: "0 10px" }}
						onClick={hanldeDownload}
					>
						{t("single.buttons.run")}
					</Button>
				</div>
				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{
						color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)",
						background: showFilters ? "#c1e5ca" : "",
					}}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>

			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<SearchInput onSearch={handleSearch} />
						{/* <TenantDropdown data={tenantNameData} /> */}
						<RoleDropdown
							roleData={roleData}
							selectedRoles={roleDropdown}
							onSave={(value) => handleRoleDropdown(value)}
						/>
						<StatusDropdown
							selectedStatus={statusDropdown}
							onSave={(value) => handleStatusDropdown(value)}
						/>
					</Box>
					<IconButton
						onClick={clearFilters}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)}
			<div style={{ flex: 1, overflow: "auto" }}>
				<Table
					stickyHeader
					aria-label="sticky table"
					size="small"
					className={styles.table}
				>
					<TableHead>
						<TableRow>
							<TableCell>
								<TableSortLabel
									active={sortByField === "tenant_code"}
									onClick={() => {
										setSortByField("tenant_code")
										let sort: any = {
											...sortOrder,
											tenant_code: sortOrder.tenant_code === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.tenant_code}
								>
									{t("userSystem.tenantCode")}
								</TableSortLabel>

							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "tenant_name"}
									onClick={() => {
										setSortByField("tenant_name")
										let sort: any = {
											...sortOrder,
											tenant_name: sortOrder.tenant_name === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.tenant_name}
								>
									{t("userSystem.tenantName")}
								</TableSortLabel>

							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "role_names"}
									onClick={() => {
										setSortByField("role_names")
										let sort: any = {
											...sortOrder,
											role_names: sortOrder.role_names === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.role_names}
								>
									{t("userSystem.roleName")}
								</TableSortLabel>

							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "user_count"}
									onClick={() => {
										setSortByField("user_count")
										let sort: any = {
											...sortOrder,
											user_count: sortOrder.user_count === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.user_count}
								>
									{t("userSystem.numberOfUsers")}
								</TableSortLabel>

							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "status"}
									onClick={() => {
										setSortByField("status")
										let sort: any = {
											...sortOrder,
											status: sortOrder.status === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.status}
								>
									{t("userSystem.status")}
								</TableSortLabel>

							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "start_time"}
									onClick={() => {
										setSortByField("start_time")
										let sort: any = {
											...sortOrder,
											start_time: sortOrder.start_time === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.start_time}
								>
									{t("userSystem.startDate")}
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "end_time"}
									onClick={() => {
										setSortByField("end_time")
										let sort: any = {
											...sortOrder,
											end_time: sortOrder.end_time === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.end_time}
								>
									{t("userSystem.endDate")}
								</TableSortLabel>
							</TableCell>
							{context.menu && context.menu.find((item) => item.id === "100002") && <TableCell width={120}></TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : (
							tableData.map((tenant: any) => (
								<TableRow
									key={tenant.id}
									hover
									onMouseEnter={() => setHoveredRow(tenant.id)}
									onMouseLeave={() => setHoveredRow(null)}
									className={styles.row}
								>
									<TableCell>{tenant.tenantCode}</TableCell>
									<TableCell>{tenant.tenantName}</TableCell>
									<TableCell>{tenant.roleNames}</TableCell>
									<TableCell>{(tenant.existUser || 0) + " / " + (tenant.userCount || 0)}</TableCell>
									<TableCell>
										<div style={{ display: "flex", alignItems: "center" }}>
											<span
												className={styles.status}
												style={{ backgroundColor: statusBgColor(tenant.status) }}
											></span>
											<span>{getStatusText(tenant.status)}</span>
										</div>
									</TableCell>
									<TableCell>{tenant.startTime}</TableCell>
									<TableCell>{tenant.endTime}</TableCell>
									{context.menu && context.menu.find((item) => item.id === "100002") && (
										<TableCell>
											{hoveredRow === tenant.id && (
												<div className={styles.delete}>
													<div
														className={`${styles.icons} delete-set`}
														onClick={(evt) => evt.stopPropagation()}
													>
														<div
															className="tooltip tooltip-bottom"
															data-tip={t("tooltip.edit")}
														>
															<IconButton
																onClick={() => {
																	setOpen(true);
																	setCurrentTenant(tenant);
																}}
															>
																<span className="material-icons">edit</span>
															</IconButton>
														</div>
														<div
															className="tooltip tooltip-bottom"
															data-tip={t("tooltip.delete")}
														>
															<IconButton onClick={() => handleDelete(tenant)}>
																<span className="material-icons">delete</span>
															</IconButton>
														</div>
													</div>
												</div>
											)}
										</TableCell>
									)}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</div>
			<div style={{ marginLeft: "auto" }}>
				<TablePagination
					component="div"
					count={totalCount}
					rowsPerPageOptions={[3, 10, 15, 25]}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={t("userSystem.rowsPerPage")}
					slotProps={{
						select: {
							native: true,
						},
					}}
				/>
			</div>
			{openDeleteTenantModal && currentTenant && (
				<DeleteTenantModal
					// tenantId={openDeleteTenantModal.tenantId}
					// tenantName={openDeleteTenantModal.tenantName}
					data={currentTenant}
					initData={initData}
					onClose={closeDeleteTenantModal}
				/>
			)}
			{open && (
				<TenantAddModal
					open={open}
					onClose={() => setOpen(false)}
					onSave={handleSave}
					data={currentTenant}
					roles={roleData}
				/>
			)}
		</div>
	);
};

export default Tenant;
