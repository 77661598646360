import { MenuItem } from "./type";

export const getChildIds = (id: string, menuData: MenuItem[]) => {
	const childIds: string[] = [];
	menuData.forEach((item) => {
		if (item.parentId === id) {
			childIds.push(item.id);
			childIds.push(...getChildIds(item.id, menuData));
		}
	});
	return childIds;
};
