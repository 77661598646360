import { LocationData } from "./models";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const getCounts = (locations?: LocationData[]): string => {
  if (!locations || locations.length === 0) return "0/0";
  return `${locations?.filter((location: LocationData) => location.checked).length}/${locations?.length
    }`;
}

export const getCheckedCounts = (locations?: LocationData[]): string => {
  if (!locations || locations.length === 0) return "0";
  return `${locations?.filter((location: LocationData) => location.checked).length}`;
}

export const formatCurrency = (num: number): string =>
  formatter.format(num).replace("$", "");

export const statusBgColor = (status: string) => {
  switch (status) {
    case "1":
      return "#52C41A"
    case "2":
      return "#FF4D4F"
    case "3":
      return "#FAAD14"
    case "4":
      return "#999999"
    default:
      return "#999999"
  }
}

export const statusText = (status: string) => {
  switch (status) {
    case "1":
      return "active"
    case "2":
      return "overdue"
    case "3":
      return "closed"
    case "4":
      return "notActivated"
    default:
      return status
  }
}

export const statusText1 = (status: string) => {
  switch (status) {
    case "1":
      return "success"
    default:
      return "failed"
  }
}